import React from 'react';
import PropTypes from 'prop-types';

import CertificateQrReader from '../parts/qrReader';
import { Identity } from '../identity';
import { logger } from '../utils/logger';


const CheckCertificateForm = ({ fetchCertificates, identity, isLoading, setIdentity }) => {
  const handleQrScan = (personalCode) => {
    logger.log('handleQrScan()')
    const newIdentity = Identity.fromPersonalCode(personalCode);
    setIdentity(newIdentity);
    // @todo avoid/fix passing of pepper around
    fetchCertificates(newIdentity.hash, newIdentity.pepper);
  };
  return (!identity.hash) && <CertificateQrReader onScan={handleQrScan} disabled={isLoading} />;
};


CheckCertificateForm.propTypes = {
  fetchCertificates: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIdentity: PropTypes.func.isRequired,
  identity: Identity.propType.isRequired,
};


export default CheckCertificateForm;

import React from 'react';
import PropTypes from 'prop-types';


const Loader = ({ className }) => <div className={`loader-ripple ${className}`}><div /><div /></div>;


Loader.defaultProps = {
    className: '',
};

Loader.propTypes = {
    className: PropTypes.string,
};


export default Loader;

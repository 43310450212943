import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '/styles/app.scss';

module.hot && module.hot.accept()

if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const { worker } = require('./certificates/mocks/msw')
    worker.start()
}

var mountNode = document.getElementById('app');
ReactDOM.render(<App />, mountNode);

/* eslint-disable react/no-string-refs */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-scanner';

import { Button } from '../../core/forms/fields';
import { gettext } from '../mocks';
import { logger } from '../utils/logger';


class CertificateQrReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legacyMode: false,
      permissionAsked: false,
      result: '',
    };
    this.handleScan = this.handleScan.bind(this);
    this.openImageDialog = this.openImageDialog.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleError = this.handleError.bind(this);
    this.qrReaderRef = React.createRef();
  }

  /**
   * Workaround for facingMode bug
   *
   * @link https://github.com/kybarg/react-qr-scanner/issues/6
   *
   * @todo revisit/refactor
   */
  componentDidMount() {
    const detectCamera = this.detectCamera;
    (async function effectDetectCamera() {
      await detectCamera();
    })();
  }
  detectCamera = async () => {
    // navigator.mediaDevices will be undefined and throw TypeError in non-secure (http) context
    // navigator.mediaDevices will throw DOMException if user does not grant permission
    // in both cases we set permissionAsked as true which initializes the QrReader
    // because legacyMode doesn't work without initializing the QrReader (this.qrReaderRef is undefined)
    // reader permission errors are then handled by the reader with handleError and legacyMode
    try {
      await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
      await navigator.mediaDevices.enumerateDevices();
      this.setState({ permissionAsked: true });
    } catch (error) {
      switch (true) {
        case error instanceof TypeError:
        case error instanceof DOMException:
          this.setState({ permissionAsked: true });
          break;
        default:
          logger.error(error);
          break;
      }
    }
  };

  handleError(error) {
    logger.error(error);
    this.setState({ legacyMode: true });
  }

  handleScan(result) {
    const { onScan } = this.props;
    if (result) {
      logger.log(`QR Reader result: "${result}"`);
      this.setState({ result });
      onScan(result);
    }
  }

  openImageDialog() {
    this.qrReaderRef.current.openImageDialog();
  }

  handleLoad() {
    this.setState({ loading: false });
  }

  render() {
    const { disabled } = this.props;
    const { legacyMode, permissionAsked } = this.state;

    return (
      <Fragment>
        {permissionAsked && (<QrReader
          ref={this.qrReaderRef}
          className={`qr-reader ${legacyMode && 'legacy-mode'}`}
          onError={this.handleError}
          onScan={this.handleScan}
          legacyMode={legacyMode}
          onLoad={this.handleLoad}
          facingMode="rear"
          delay={1000}
        />)}
        {legacyMode && (
          <Button className="hollow margin-bottom" onClick={this.openImageDialog} disabled={disabled}>
            {gettext('Upload QR Code')}
          </Button>
        )
        }
      </Fragment>
    );
  }
}


CertificateQrReader.defaultProps = {
  disabled: false,
};

CertificateQrReader.propTypes = {
  onScan: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};


export default CertificateQrReader;

import React from 'react';
import PropTypes from 'prop-types';

import { formatDate, formatTime } from '../utils/date';
import { gettext } from '../mocks';
import { Identity } from '../identity';
import CheckMark from './checkMark';
import { Button } from '../../core/forms/fields';
import { logger } from '../utils/logger';


const CertificateResult = ({ certificate, identity, showCertificateOwner }) => {
  if (Object.keys(certificate).length === 0 && certificate.constructor === Object) {
    return null;
  }
  const handleIdentityClick = () => { window.location.hash = '#verifyIdentity'; }
  logger.debug('[rendering result]')
  return (
    <div className={`big-top-margin certificate-result ${!identity.isKnown  && 'certificate-result-unknown-id'}`}>

      { showCertificateOwner && (
        <div className="align-text-center padded-top secondary-color">
          {gettext(`Responsible Certificate Owner: ${certificate.ownerName}`)}
        </div>
      )}

      <h3 className="align-text-center padded-top">{certificate.description}</h3>

      <div className="align-text-center">
        {
          certificate.revoked
            ? gettext('This certificate has been revoked.')
            : certificate.expired && gettext('This certificate has expired')
        }
      </div>

      {!certificate.revoked && !certificate.expired && <CheckMark size="medium" />}

      <dl className="dl-with-accent">

        <div>
          <dd className={!identity.isKnown && 'warning-icon'}>
            {identity.isKnown ? gettext('Identity') : gettext('Unknown Identity')}
          </dd>
          <dt>
            {identity.isKnown ? identity.values.join(' ') : (
              <Button className="margin-top-half button hollow" onClick={handleIdentityClick} >
                {gettext('Verify Identity')}
              </Button>
            )}
          </dt>
        </div>

        <div>
          <dd>{gettext('Issuer Name and ID')}</dd>
          <dt>
              <div>{certificate.issuerName}</div>
              {certificate.issuerIds.map(issuerId => <div>{issuerId}</div>)}
            </dt>
        </div>

        {certificate.expiryDate && (
          <div>
            <dd>{gettext('Valid Until')}</dd>
            <dt>
              <div>{formatDate(certificate.expiryDate)}</div>
              <div>{formatTime(certificate.expiryDate)}</div>
            </dt>
          </div>
        )}

        <div>
          <dd>{gettext('Test Date and Time')}</dd>
          <dt>
            <div>{formatDate(certificate.sampleDate)}</div>
            <div>{formatTime(certificate.sampleDate)}</div>
          </dt>
        </div>

        {certificate.extraData && (
          <div>
            <dd>{gettext('Public Data')}</dd>
            <dt>{certificate.extraData}</dt>
          </div>
        )}

        {certificate.encryptedData && (
          <div>
            <dd>{gettext('Private Data')}</dd>
            <dt>{certificate.encryptedData}</dt>
          </div>
        )}

      </dl>

    </div>
  );
};


CertificateResult.defaultProps = {
  certificate: {},
  showCertificateOwner: false,
};

CertificateResult.propTypes = {
  certificate: PropTypes.shape({
    description: PropTypes.string,
    expiryDate: PropTypes.instanceOf(Date),
    sampleDate: PropTypes.instanceOf(Date),
    issuerName: PropTypes.string,
    issuerIds: PropTypes.arrayOf(PropTypes.string),
    ownerName: PropTypes.string,
    expired: PropTypes.bool,
    revoked: PropTypes.bool,
    extraData: PropTypes.string,
    encryptedData: PropTypes.string,
  }),
  identity: Identity.propType.isRequired,
  showCertificateOwner: PropTypes.bool,
};


export default CertificateResult;

import { rest, setupWorker } from 'msw';
import { certificate } from './certificate';
import { APIBaseURL } from '../settings';

const mockCertificateArray = (_req, res, ctx) => res(ctx.status(200), ctx.json([certificate]));

const handlers = [
    rest.get(`${APIBaseURL}/certificates/check/:personHash/`, mockCertificateArray),
];

export const worker = setupWorker(...handlers)



import React from 'react';

import CheckCertificateView from './certificates/checkCertificateView';


const App = () => (
  <div className="container container-app">
    <CheckCertificateView />
  </div>
);


export default App;

export const certificate = {
    'description': 'Test Certificate',
    'test_kit_id': 'TEK-27821355123-1241',
    'sample_timestamp': 1603317600,
    'expiry_timestamp': '',
    'extra_data': '',
    'encrypted_data': '',
    'issuer_name': 'Example Issuer',
    'issuer_ids': ['CH-EI-00765432134561'],
    'revoked': false,
};

/**
 * A generic button.
 *
 * TODO add other props as necessary (className, name, value)
 */

import React from 'react';
import PropTypes from 'prop-types';


const Button = ({ children, className, disabled, type, onClick, ...props }) => {
  let classNames = 'button';
  classNames += className ? ` ${className}` : '';
  return (
    // eslint-disable-next-line react/button-has-type
    <button className={classNames} type={type} disabled={disabled} onClick={onClick} {...props}>{children}</button>
  );
};


Button.defaultProps = {
  className: '',
  disabled: false,
  type: 'button',
  onClick: () => {},
};


Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  onClick: PropTypes.func,
};


export default Button;

import React, { useEffect, useRef, useState, Fragment } from 'react';
import axios from 'axios'

import CheckCertificateForm from './form';
import CertificateResult from './result';
import Loader from './loader';
import { EMBED_QUERY_PARAM, HASH_QUERY_PARAM } from '../constants';
import { APIBaseURL } from '../settings';
import { decrypt, hasCryptoSupport } from '../utils/encryption';
import Instructions from './instructions';
import VerifyIdentityForm from './verifyIdentityForm';
import { gettext } from '../mocks';
import { Identity } from '../identity';
import { logger } from '../utils/logger';

const CheckCertificateView = () => {
  const resultRef = useRef(null);

  const [certificatesFetched, setCertificatesFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [identity, setIdentity] = useState(new Identity({}));
  const [URLHash, setURLHash] = useState('');
  const [activeScreenKey, setActiveScreenKey] = useState('checkCertificate');
  const [certificates, setCertificates] = useState([]);

  const { searchParams } = new URL(window.location.href);
  const isEmbedded = searchParams.get(EMBED_QUERY_PARAM);

  // Debug states
  useEffect(() => { logger.log('> certificatesFetched:', certificatesFetched); }, [certificatesFetched]);
  useEffect(() => { logger.log('> certificates:', certificates); }, [certificates]);

  // Handle fetching finished
  const handleFetchingFinished = (certificates) => {
    logger.log('handleFetchingFinished()')
    setCertificates(certificates);
    setCertificatesFetched(true);
    setIsLoading(false);
    resultRef.current && resultRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  // Handle successful server response
  // @todo avoid/fix passing of pepper around
  const handleServerResponse = ({ data, pepperTemp }) => {
    logger.log('handleServerResponse()');
    if (!data.length) {
      logger.warn('Received an empty response.')
      handleFetchingFinished([]);
      return;
    }
    const certificatesPromises = data.map(
      // @todo move setting of certificate elsewhere
      // @todo consider Certificate class
      (item) => {
        const certificate = {
          ownerName: item.owner_name,
          issuerName: item.issuer_name,
          issuerIds: item.issuer_ids,
          testKitId: item.test_kit_id,
          extraData: item.extra_data,
          encryptedData: '',
          expiryDate: !!item.expiry_timestamp ? new Date(item.expiry_timestamp * 1000) : null,
          sampleDate: new Date(item.sample_timestamp * 1000),
          expired: !!item.expiry_timestamp && item.expiry_timestamp < Math.floor(Date.now() / 1000),
          revoked: item.revoked,
          description: item.description,
        };
        if (!item.encrypted_data || !hasCryptoSupport) {
          return certificate;
        }
        // @todo avoid/fix passing of pepper around
        return decrypt(item.encrypted_data, pepperTemp).then(
          decryptedData => ({ ...certificate, encryptedData: decryptedData }),
        );
      },
    );
    Promise.all(certificatesPromises).then(certificates => handleFetchingFinished(certificates));
  };

  // Handle server error
  const handleServerError = (error) => {
    logger.error(error);
    handleFetchingFinished([]);
  };

  // Fetch certificates from server
  // @todo avoid/fix passing of pepper around
  const fetchCertificates = (hash, pepperTemp) => {
    logger.log('fetchCertificates()');
    if (!window.location.hash) {
      // @todo abstract navigation actions
      window.location.hash = '#certificateResults';
    }
    if (!hash) {
      handleServerError('Personal code could not be parsed.');
      return;
    }
    setIsLoading(true);
    setCertificatesFetched(false);
    axios
      .get(`${APIBaseURL}/certificates/check/${hash}/`)
      // @todo avoid/fix passing of pepper around
      .then(response => { response.pepperTemp = pepperTemp; handleServerResponse(response); } )
      .catch(handleServerError)
  };

  // Set identity on load
  useEffect(() => {
    setIdentity(Identity.fromPersonalCode(window.location.href));
  }, []);

  // Initiate fetching if hash is found in URL search params
  useEffect(() => {
    const hash = searchParams.get(HASH_QUERY_PARAM);
    hash && fetchCertificates(hash);
  }, []);

  // Set URLHash based on initial url hash
  useEffect(() => {
    const initScreen = window.location.hash.substring(1);
    // @todo consider better handling of this situation
    if (['certificateResults', 'verifyIdentity'].includes(initScreen) && !searchParams.get(HASH_QUERY_PARAM)) {
      window.location.hash = '';
    }
    window.location.hash && setURLHash(initScreen);
  }, []);

  // Update URLHash when the hash changes
  window.addEventListener('hashchange', () => {
    setURLHash(window.location.hash.substring(1));
  });

  // Update screen whenever URLHash changes
  useEffect(() => {
    if (URLHash) {
      logger.log('> activeScreen:', `"${URLHash}"`);
      setActiveScreenKey(URLHash);
    }
  }, [URLHash]);

  // List of certificate results
  // @todo rewrite into a proper component
  let certificatesList = <Fragment />;
  if (certificatesFetched) {
    if (certificates && certificates.length) {
      certificatesList = certificates.map(
        certificate => <CertificateResult key={certificate.testKitId} certificate={certificate} identity={identity} />,
      );
    } else {
      certificatesList = (
        <div className="certificate-result certificate-result-empty centered align-flex-items-center">
          {gettext('No certificates were found.')}
        </div>
      );
    }
  } else {
    certificatesList = <Loader className="margin-top-4x" />;
  }

  // Define screens
  const screensToComponents = {
    checkCertificate: (
      <CheckCertificateForm
        fetchCertificates={fetchCertificates}
        isLoading={isLoading}
        identity={identity}
        setIdentity={setIdentity}
      />
    ),
    certificateResults: <div ref={resultRef}>{certificatesList}</div>,
    verifyIdentity: <VerifyIdentityForm setIdentity={setIdentity} identity={identity} />,
  };

  // Get active screen
  const ActiveScreen = () => screensToComponents[activeScreenKey];

  // Render output
  logger.debug('[re-render]');
  return (
    <div className="top-margin limited-width">
      {!isEmbedded && (
        <Instructions
          withQr={!searchParams.get(HASH_QUERY_PARAM)}
          onHeadingClick={(_e) => {
            window.location.reload();
          }}
        />
      )}
      <ActiveScreen />
    </div>
  );
};


export default CheckCertificateView;

import PropTypes from 'prop-types';
import { sha3 } from 'web3-utils';

import { HASH_QUERY_PARAM, PROCEDURE_CODE_QUERY_PARAM } from './constants';
import { fieldsByProcedureCode, SEPARATOR } from './constants';
import { logger } from './utils/logger';


export class Identity {
  constructor({ fields, hash, pepper, procedureCode }) {
    this.fields = fields || [];
    this.hash = hash;
    this.pepper = pepper;
    this.procedureCode = procedureCode || '0000';
    this.isKnown && !this.hash && this.generateHash();
  }
  static fromPersonalCode(personalCode) {
    let args = {}
    if (personalCode) {
      if (personalCode.match(/^https?:\/\//)) {
        args = parsePersonalCodeURL(personalCode);
      }
      if (personalCode.includes(SEPARATOR)) {
        args = parsePersonalCodeWithSeparator(personalCode);
      }
    }
    return new this(args);
  }
  static propType = PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
    })),
    hash: PropTypes.string,
    pepper: PropTypes.string,
    procedureCode: PropTypes.string,
  });
  get values() {
    return this.fields ? this.fields.map(f => f.value).filter(f => !!f) : [];
  }
  get isKnown() {
    return !!this.values.length && !!this.pepper;
  }
  generateHash() {
    this.hash = sha3([this.procedureCode, this.values.join(SEPARATOR), this.pepper].join(SEPARATOR));
  }
}

// @todo consider moving to Identity
const parsePersonalCodeURL = (url) => {
  const { searchParams } = new URL(url);
  const procedureCode = (
    searchParams.get(PROCEDURE_CODE_QUERY_PARAM)
    || searchParams.get(PROCEDURE_CODE_QUERY_PARAM.toUpperCase())
    || '0000'
  );
  logger.log('> procedureCode:', procedureCode);
  return {
    hash: searchParams.get(HASH_QUERY_PARAM),
    procedureCode,
  };
}

// @todo consider moving to Identity
const parsePersonalCodeWithSeparator = (personalCode) => {
  const personalCodeArray = personalCode.split(SEPARATOR);
  const pepper = personalCodeArray.pop();
  const procedureCode = personalCodeArray.shift();
  return {
    fields: personalCodeArray.map((value, i) => ({ ...fieldsByProcedureCode[procedureCode][i], value })),
    pepper,
    procedureCode,
  };
}

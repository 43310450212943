import React, { Fragment, useState } from 'react';

import { gettext } from '../mocks';
import { Button } from '../../core/forms/fields';
import { fieldsByProcedureCode } from '../constants';
import { Identity } from '../identity';
import { logger } from '../utils/logger';


const VerifyIdentityForm = ({ setIdentity, identity }) => {

  const [fieldsValues, setFieldsValues] = useState({});
  const [extraClassName, setExtraClassName] = useState('');

  const fields = fieldsByProcedureCode[identity.procedureCode || '0000'];

  const setFieldValue = (name, value) => {
    setFieldsValues({ ...fieldsValues, [name]: value.trim() })
  };

  const handleBackButtonClick = () => { window.location.hash = '#certificateResults'; }

  const handleVerifySubmit = (e) => {
    e.preventDefault();
    const { pepper, ...values } = fieldsValues;
    const newIdentity = new Identity({
      fields: fields.map(field => ({...field, value: values[field.name]})),
      procedureCode: identity.procedureCode,
      pepper,
    });
    if (fields.length !== newIdentity.values.length) {
      logger.warn(`Field content validation failed: ${fields.length} !== ${newIdentity.values.length}`);
      setExtraClassName('animation-shake');
      return;
    }
    if (identity.hash !== newIdentity.hash) {
      logger.warn(`Identity check failed: ${identity.hash} !== ${newIdentity.hash}`);
      setExtraClassName('animation-shake');
      return;
    }
    setIdentity(newIdentity);
    window.location.hash = '#certificateResults';
  }

  return (
    <div className={`big-top-margin certificate-result ${extraClassName}`} onAnimationEnd={() => setExtraClassName('')}>
      <form onSubmit={handleVerifySubmit}>
        {
          fields.map(field => (
            // @todo separate into IdentityFieldInput component
            <Fragment>
              <label>{field.label}</label>
              <input {...field.attrs} name={field.name} onChange={(e) => setFieldValue(field.name, e.target.value)} />
            </Fragment>
          ))
        }
        {/* @todo separate into SecurityCodeInput component */}
        <label>{gettext('Security Code')}</label>
        <input type="text" name="pepper" onChange={(e) => setFieldValue('pepper', e.target.value)} />
        <Button type="submit" className="button hollow">
          {gettext('Verify')}
        </Button>
        <Button className="button borderless secondary margin-top" onClick={handleBackButtonClick} >
          {gettext('Back to Certificate')}
        </Button>
      </form>
    </div>
  );
};


export default VerifyIdentityForm;

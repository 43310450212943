/**
 * Encryption utilities
 *
 * @todo review
 * @todo separate into private shared library
 */

import { bytesToHex, hexToBytes } from 'web3-utils';


const SALT = 'j6JoqG%!9xF7IKCk';
const IV_LENGTH = 12;


const hasCryptoSupport = !!(window.crypto && window.crypto.subtle && window.TextEncoder);

const importKey = password => window.crypto.subtle.importKey(
    'raw', new TextEncoder().encode(password), { name: 'PBKDF2' }, false, ['deriveBits', 'deriveKey'],
);

const deriveKey = (importedKey, salt) => window.crypto.subtle.deriveKey(
    { name: 'PBKDF2', iterations: 100000, hash: 'SHA-256', salt },
    importedKey,
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt'],
);

const encrypt = async (value, password) => {
    const encoder = new TextEncoder();
    const importedKey = await importKey(password);
    const key = await deriveKey(importedKey, encoder.encode(SALT));
    const iv = window.crypto.getRandomValues(new Uint8Array(IV_LENGTH));
    const cipherArrayBuffer = await window.crypto.subtle.encrypt({ name: 'AES-GCM', iv }, key, encoder.encode(value));
    const cipher = new Uint8Array(cipherArrayBuffer, 0, cipherArrayBuffer.byteLength);
    const result = new Uint8Array(iv.length + cipher.length);
    result.set(iv);
    result.set(cipher, iv.length);
    return bytesToHex(result);
};

const decrypt = async (value, password) => {
    const result = Uint8Array.from(hexToBytes(value));
    const iv = result.subarray(0, IV_LENGTH);
    const cipher = result.subarray(IV_LENGTH, result.length);
    const importedKey = await importKey(password);
    const key = await deriveKey(importedKey, new TextEncoder().encode(SALT));
    try {
        const decrypted = await window.crypto.subtle.decrypt({ name: 'AES-GCM', iv }, key, cipher);
        return new TextDecoder().decode(decrypted);
    } catch (e) {
        console.error(e);
        return '';
    }
};

export { hasCryptoSupport, encrypt, decrypt };

import { gettext } from './mocks';

export const DEBUG_QUERY_PARAM = '_debug';
export const EMBED_QUERY_PARAM = 'embed';
export const HASH_QUERY_PARAM = 'hash';
export const PROCEDURE_CODE_QUERY_PARAM = 'p';

export const SEPARATOR = String.fromCharCode(31);

export const fieldsByProcedureCode = {
  '0000': [
    { name: 'identity', label: gettext('Identity'), attrs: { type: 'text' } },
  ],
  '0001': [
    { name: 'firstName', label: gettext('First Name'), attrs: { type: 'text' } },
    { name: 'lastName', label: gettext('Last Name'), attrs: { type: 'text' } },
  ],
  '0002': [
    { name: 'firstName', label: gettext('First Name'), attrs: { type: 'text' } },
    { name: 'lastName', label: gettext('Last Name'), attrs: { type: 'text' } },
    { name: 'dateOfBirth', label: gettext('Date of Birth'), attrs: { type: 'date' } },
  ],
  '0003': [
    { name: 'firstName', label: gettext('First Name'), attrs: { type: 'text' } },
    { name: 'lastName', label: gettext('Last Name'), attrs: { type: 'text' } },
    { name: 'dateOfBirth', label: gettext('Date of Birth'), attrs: { type: 'date' } },
    { name: 'idNumber', label: gettext('ID Number'), attrs: { type: 'text', inputmode: 'numeric' } },
  ],
  '0004': [
    { name: 'firstName', label: gettext('First Name'), attrs: { type: 'text' } },
    { name: 'lastName', label: gettext('Last Name'), attrs: { type: 'text' } },
    { name: 'dateOfBirth', label: gettext('Date of Birth'), attrs: { type: 'date' } },
    { name: 'taxNumber', label: gettext('Tax Number'), attrs: { type: 'text', inputmode: 'numeric' } },
  ],
};

/**
 * Date and time utils.
 */

export const formatDate = date => {
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${day}.${month}.${year}`;
};

export const formatTime = date => {
    const hours = (`0${date.getHours()}`).slice(-2);
    const minutes = (`0${date.getMinutes()}`).slice(-2);
    return `${hours}:${minutes}`;
};

export const formatDateTime = date => `${formatDate(date)} ${formatTime(date)}`;

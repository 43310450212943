import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { gettext } from '../mocks';

const Instructions = ({onHeadingClick, withQr}) => (
  <Fragment>
    <h2 className="title big-top-margin align-text-center" onClick={onHeadingClick}>
      {gettext('Check Certificate')}
    </h2>
    <ol className="numbered">
      {withQr && <li>{gettext('Scan QR code')}</li>}
      <li>{gettext('Verify identity')}</li>
      <li>{gettext('Check result and validity')}</li>
    </ol>
  </Fragment>
);

export default Instructions;

Instructions.defaultProps = {
  onHeadingClick: () => {},
  withQr: true,
};

Instructions.propTypes = {
  onHeadingClick: PropTypes.func,
  withQr: PropTypes.bool,
};
